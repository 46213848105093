import React, {Component} from 'react';

import "./index.css";

const appstore = "https://itunes.apple.com/app/id1559545243";
const instagram = "https://www.instagram.com/mealhive";
const presskit = "https://drive.google.com/drive/folders/10olnZTUlXXHknuHbSSoE3nAqGbigYH3A?usp=sharing";

class RecipeApp extends Component {

  render() {
    return (
      <div id="recipeapp">
        <div id="header" />
        <div id="content">

        <a id="icon" href={appstore} />

        <h1>MealHive: Meal planner</h1>
        <h2>Plan, Cook, Eat Happy!</h2>

        <a id="appstore" href={appstore} />

        <ul id="footer">
        <li>© 2023 Materik AB</li>
        <li><a href={instagram}>Instagram</a></li>
        {/* <li><a href={presskit}>Press kit</a></li> */}
        </ul>
        </div>
      </div>
    );
  }

}

export default RecipeApp;
